const ExperimentDetails = [
  {
    title: "Pyramid Experiment",
    embedId: "7Vpg5p99YIQ",
  },
  {
    title: "How To Make Pyramid",
    embedId: "TjyO0vSrOBU",
  },
];

export default ExperimentDetails;
