import React from "react";

import patriji from "../images/meditation.png";
import pyramid_meditation from "../images/pyramid_meditation3.jpg";

function PyramidMeditation() {
  return (
    <div className="procedure">
      <h3 style={{ color: "#b43e10" }}>What is Procedure for Meditation?</h3>
      <div className="postureImg">
        <img className="meditationPosture" src={pyramid_meditation} alt="" />
      </div>
      <div>
        <p>
          Meditation done inside a Pyramid is thrice more powerful Meditation
          done inside a pyramid, or underneath a pyramid, is called as Pyramid
          Meditation.
        </p>{" "}
         
        <p>
          Many people experience feelings ranging from calmness to extreme
          euphoria during their meditation sessions inside the pyramids.
        </p>
        <p>
          Pyramids provide most effective high-energy environments for beginners
          of meditation.
        </p>
        <p>
          Pyramids help to reduce the level of stress and tension in the
          physical body Most people who have experimented with Pyramid
          Meditation, describe themselves as experiencing a total relaxation of
          their body, followed by the shutting out of unnecessary external
          stimuli and irrelevant thoughts and finally achieving an altered state
          of consciousness which allows them to concentrate on deeper inner
          levels.
        </p>
      </div>
    </div>
  );
}

export default PyramidMeditation;
