import React from "react";

import patriji from "../images/meditation.png";
import meditationPosture from "../images/MeditationPosture.jpeg";

function Procedure() {
  return (
    <div className="procedure">
      <h3 style={{ color: "#b43e10" }}>What is Procedure for Meditation?</h3>
      <div className="postureImg">
        <img className="meditationPosture" src={meditationPosture} alt="" />
      </div>
      <div>
        <p>
          Hands should be clasped and all fingers should go into all fingers
        </p>
        <p>The feet should be crossed</p>
        <p>The eyes should be closed</p>
        <p>Observe the normal natural breath</p>

        <p>
          <strong>Note</strong> – Any comfortable sitting posture can be taken…
          The posture should be as comfortable as possible…It is not at all
          necessary that we should squat on the ground… You can perfectly prefer
          a comfortable sofa…
        </p>
      </div>
    </div>
  );
}

export default Procedure;
