const LocalEventDetails = [
  {
    id: 6,
    title: "Patri Sir Birthday Celebrations 2024 at Lotus Meditation Center",
    date: "Nov 11th, 2024",
    img: require("./images/PatrijiBdayCel.jpeg"),
    location: "Lotus Meditation Center, Atlanta, GA",
  },
  {
    id: 5,
    title: "Patri Sir Birthday Celebrations 2024 at APMA Center",
    date: "Nov 10th, 2024",
    img: require("./images/PatrijiBday2024.jpeg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 4,
    title: "Kids Meditation at APMA Center",
    date: "Nov 10th, 2024",
    img: require("./images/KidsMeditation.jpeg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 3,
    title: "Patri Sir Birthday Celebrations 2023 at APMA Center",
    date: "Nov 11th, 2023",
    img: require("./images/PatriSirBday.jpg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 2,
    title: "Seth Wisdom Workshop by Bala Krishna Sir",
    date: "Oct 15th, 2023",
    img: require("./images/SethWS.jpg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 1,
    title: "Crystal Kids Meditation - Certificate Program",
    date: "Oct 29th, 2023",
    img: require("./images/Crystal_KIDS_Meditation_crtf.jpg"),
    location: "APMA Center, Atlanta, GA",
  },
];

const USA_EventDetails = [
  {
    id: 1,
    title: "Mega Meditation Summit (Dhyanma Maha Chakram) @ Dallas",
    date: "Oct 8th, 2023",
    img: require("./images/MMS_Event.jpeg"),
    location: "APMA Center, Atlanta, GA",
  },
  {
    id: 2,
    title: "Pyramid Bhumi Pooja @ Raliegh",
    date: "",
    img: require("./images/RaleighFirstPyramidLayingStone.jpg"),
    location: "Raliegh, NC",
  },
  {
    id: 3,
    title: "Patriji Amruthosthav @ Maryland",
    date: "Nov 11th, 2023",
    img: require("./images/DMMS_Dallas.jpg"),
    location: "Maryland, MD",
  },
];

export { LocalEventDetails, USA_EventDetails };
