import React from "react";

import patriji from "../images/meditation.png";
import meditationPosture from "../images/MeditationPosture.jpeg";

function WhatHappens() {
  return (
    <div className="anapanasati">
      <h3 style={{ color: "#b43e10" }}>What happens in Meditation?</h3>
      <div>
        <p>
          “When we are with the breath-energy, the mind becomes rather empty.
        </p>
        <p>
          Then, there is a tremendous in-flow of cosmic energy into the physical
          body.
        </p>
        <p>
          Gradually, the hitherto dormant third-eye begins to become activated.
        </p>
        <p>And, by and by, cosmic consciousness gets to be experienced.</p>
      </div>
    </div>
  );
}

export default WhatHappens;
