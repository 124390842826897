import React from "react";

import patriji from "../images/meditation.png";

function Anapanasati() {
  return (
    <div className="anapanasati">
      <h3 style={{ color: "#b43e10" }}>What is Anapanasati Meditation?</h3>
      <p> Ana’ means ‘In-Breath’ </p>
      <p>‘Apana’ means ‘Out-Breath’</p>
      <p>‘Sati’ means ‘Be With’</p>
      <p>
        In “Anapanasati Meditation“, the attention of the mind should constantly
        be on the normal, natural breath. The task on hand is effortful, joyful
        oneness with the breath. No ”mantra” to be chanted… no form of any ‘
        deity ‘ to be entertained in the mind… no hathayogic pranayama like ‘
        kumbhaka ‘.. holding the breath.. should be attempted.
      </p>
    </div>
  );
}

export default Anapanasati;
