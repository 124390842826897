const ContactDetails = [
  {
    id: 1,
    name: "Srikanth Garlla",
    phone: "+1 (603) 217-4004",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 2,
    name: "Sheetal Pawar",
    phone: "+1 (636) 489-8585",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 3,
    name: "Arun Kumar Alladurgam",
    phone: "+1 (251) 509-5151",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 4,
    name: "Praveen Pippiri",
    phone: "+1 (404) 947-0924",
    city: "Atlanta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
];

export default ContactDetails;
